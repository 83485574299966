import PiercingCard from "./PiercingCard";

export type PiercingDetails = {
    "title": string
    "price": number,
    "duration": string,
    "description": string,
    "src": any
}

export type PiercingGalleryProps = {
    piercings: PiercingDetails[]
}

function PiercingGallery(props: PiercingGalleryProps) {
    return (
        <div className="pb-8 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 lg:gap-8 justify-around">
            {
                props.piercings.map((piercing, index) => {
                    return (
                        <PiercingCard key={index}
                                      src={piercing.src}
                                      title={piercing.title}
                                      description={piercing.description}
                                      price={piercing.price}/>
                    );
                })
            }
        </div>
    );
}

export default PiercingGallery;
