import { useState, useEffect } from "react";
import { ReactComponent as LogoBackground } from '../../images/logo.svg';
import BioContent from "../../data/bio.json";

function Bio() {

    const [bio, setBio] = useState<string[]>([]);

    useEffect(() => {
        setBio(BioContent);
    }, []);

    return (
        <div className="bg-gray-100">
            <div className="w-full h-48 2xl:h-52 bg-gray-800 transform -skew-y-6">
            </div>
            <div className="-mt-32 bg-gradient-to-t from-gray-900 to-gray-800 transform lg:-mt-48 2xl:-mt-52">
                <div className="container grid grid-cols-1 mx-auto lg:auto-rows-auto lg:py-8">
                    <div className="col-start-1 row-start-1 justify-self-center self-end w-4/5 lg:self-center lg:justify-self-end lg:col-start-1 lg:row-start-1 lg:col-span-1 lg:mr-16 lg:w-2/5 lg:p-0 xl:w-1/3 2xl:w-1/3">
                        <LogoBackground className="hidden fill-current stroke-current lg:block text-background-gray lg:text-petrol-400 lg:mx-auto" />
                    </div>
                    <div className="col-start-1 row-start-1 self-center lg:pb-1 lg:pl-1 lg:col-start-1 lg:col-span-1 lg:row-start-1">
                        <div className="p-2 lg:p-4 lg:w-2/3 xl:w-3/5 xl:ml-32 2xl:w-1/2 2xl:ml-64">
                            <h1 className="font-Forum relative text-4xl text-gray-200 lg:text-5xl 2xl:text-5xl mb-6">Meine berufliche Geschichte</h1>
                            <ul className="text-gray-200">
                                {
                                    bio.map((bio, index) => {
                                        return (
                                            <div key={index} className="flex mb-4">
                                                <svg className="flex-none w-6 h-6 text-petrol-400" fill="none" stroke="currentColor" strokeWidth="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                                                    <path strokeLinecap="round" stroke-line-join="round" d="M4.26 10.147a60.436 60.436 0 00-.491 6.347A48.627 48.627 0 0112 20.904a48.627 48.627 0 018.232-4.41 60.46 60.46 0 00-.491-6.347m-15.482 0a50.57 50.57 0 00-2.658-.813A59.905 59.905 0 0112 3.493a59.902 59.902 0 0110.399 5.84c-.896.248-1.783.52-2.658.814m-15.482 0A50.697 50.697 0 0112 13.489a50.702 50.702 0 017.74-3.342M6.75 15a.75.75 0 100-1.5.75.75 0 000 1.5zm0 0v-3.675A55.378 55.378 0 0112 8.443m-7.007 11.55A5.981 5.981 0 006.75 15.75v-1.5"></path>
                                                </svg>
                                                <li className="pl-2 text-lg 2xl:text-xl leading-6">{bio}</li>
                                            </div>
                                        );
                                    })
                                }
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Bio;
