import React, {useEffect, useState} from "react";
import {Button, FormControl, IconButton} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

type FileUploadProps = {
    files: File[],
    setFiles: React.Dispatch<React.SetStateAction<File[]>>,
}

function FileUpload(props: FileUploadProps) {
    const [imageUrls, setImageUrls] = useState<string[]>([]);

    useEffect(() => {
        const newImageUrls: string[] = []
        setImageUrls(newImageUrls)
        props.files.forEach((file: File) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                newImageUrls.push(reader.result as string);
                setImageUrls([...newImageUrls]);
            };
        })

    }, [props.files]);

    const handleFileUpload = (event: any) => {
        const newFiles: File[] = Array.from(event.target.files);
        props.setFiles([...props.files, ...newFiles]);
    };

    const handleRemoveImage = (index: number) => {
        props.files.splice(index, 1);
        props.setFiles([...props.files])
    }


    return (<>
        <FormControl margin="normal" fullWidth>
            <Button
                variant="outlined"
                component="label"
                style={{backgroundColor: "white"}}>
                Bilder hochladen
                <input
                    hidden
                    accept="image/*"
                    multiple={true}
                    type="file"
                    onChange={handleFileUpload}/>
            </Button>
        </FormControl>

        <div className="grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-3 gap-2 lg:gap-4">
            {imageUrls.map((imageUrl, index) => {
                return (
                    <div key={`delContainer${imageUrl}`} className="relative">
                        <div key={`delDiv${imageUrl}`} onClick={() => handleRemoveImage(index)}
                             className="absolute flex align-middle justify-center top-2 right-2 w-8 h-8 rounded hover:bg-white/100 bg-white/75">
                            <IconButton color={"error"} key={`delButton${imageUrl}`} aria-label="delete" size="large">
                                <DeleteIcon key={`del${imageUrl}`} fontSize="inherit"/>
                            </IconButton>
                        </div>
                        <img className="w-full h-48 object-cover" key={`img${imageUrl}`} src={imageUrl} alt="Tattoo Referenz"/>
                    </div>
                )
            })
            }
        </div>
    </>)
}

export default FileUpload;
