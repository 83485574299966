import {useState} from "react";
import {FetchResponse, Image} from "../../shared/types";

export default function useFetch(baseUrl: string) {
    const getCSRFTokenFromCookie = (): string | undefined => {
        return document.cookie
            .split('; ')
            .find(row => row.startsWith('XSRF-TOKEN='))
            ?.split('=')[1];
    }

    const [loading, setLoading] = useState(true);

    function deleteTattooImage(id: number): Promise<{ id: number }> {
        return new Promise((resolve, reject) => {
            fetch(baseUrl + `api/admin/tattoo-images/${id}`, {
                method: "delete",
                headers: {
                    "X-XSRF-TOKEN": `${getCSRFTokenFromCookie()}`
                }
            })
                .then(response => response.json())
                .then(data => {
                    if (!data) {
                        return reject(data);
                    }
                    resolve(data);
                })
                .catch(error => {
                    reject(error);
                });
        });
    }

    function deleteWannaDoImage(id: number): Promise<{ id: number }> {
        return new Promise((resolve, reject) => {
            fetch(baseUrl + `api/admin/wanna-do-images/${id}`, {
                method: "delete",
                headers: {
                    "X-XSRF-TOKEN": `${getCSRFTokenFromCookie()}`
                }
            })
                .then(response => response.json())
                .then(data => {
                    if (!data) {
                        return reject(data);
                    }
                    resolve(data);
                })
                .catch(error => {
                    reject(error);
                });
        });
    }

    function getTattooImages(): Promise<{ images: Image[] }> {
        return new Promise((resolve, reject) => {
            fetch(baseUrl + "api/tattoo-images")
                .then(response => response.json())
                .then(data => {
                    if (!data) {
                        setLoading(false);
                        return reject(data);
                    }
                    setLoading(false);
                    resolve(data);
                })
                .catch(error => {
                    setLoading(false);
                    reject(error);
                });
        });
    }

    function getWannaDoImages(): Promise<{ images: Image[] }> {
        return new Promise((resolve, reject) => {
            fetch(baseUrl + "api/wanna-do-images")
                .then(response => response.json())
                .then(data => {
                    if (!data) {
                        setLoading(false);
                        return reject(data);
                    }
                    setLoading(false);
                    resolve(data);
                })
                .catch(error => {
                    setLoading(false);
                    reject(error);
                });
        });
    }

    function post(url: string, body: any) {
        return new Promise((resolve, reject) => {
            fetch(baseUrl + url, {
                method: "post",
                headers: {
                    "Content-Type": "application/json",
                    "X-XSRF-TOKEN": `${getCSRFTokenFromCookie()}`
                },
                body: JSON.stringify(body)
            })
                .then(response => response.json())
                .then(data => {
                    if (!data) {
                        setLoading(false);
                        return reject(data);
                    }
                    setLoading(false);
                    resolve(data);
                })
                .catch(error => {
                    setLoading(false);
                    reject(error);
                });
        });
    }

    function postForm(url: string, body: FormData): Promise<FetchResponse> {
        return new Promise((resolve, reject) => {
            fetch(baseUrl + url, {
                method: "post",
                headers: {
                    "X-XSRF-TOKEN": `${getCSRFTokenFromCookie()}`
                },
                body
            })
                .then(response => response.json())
                .then(data => {
                    if (!data) {
                        setLoading(false);
                        return reject(data);
                    }
                    setLoading(false);
                    resolve(data);
                })
                .catch(error => {
                    setLoading(false);
                    reject(error);
                });
        });
    }

    return {getTattooImages, getWannaDoImages, deleteTattooImage, deleteWannaDoImage, post, postForm, loading};
};
