import React from "react";
import {Alert, Snackbar} from "@mui/material";

export type CustomSnackbarProps = {
    actionSuccess: boolean,
    openSnackbar: boolean,
    handleSnackbarClose: (_event: React.SyntheticEvent | Event, _reason?: string) => void,
    successMessage: string,
    failMessage: string,
}

function CustomSnackbar(props: CustomSnackbarProps) {
    return (
        <Snackbar
            className="lg:mt-32 p-4"
            open={props.openSnackbar}
            autoHideDuration={4000}
            message="Note archived"
            onClose={props.handleSnackbarClose}
            anchorOrigin={{vertical: "top", horizontal: "center"}}
        >
            {props.actionSuccess ? <Alert
                    onClose={props.handleSnackbarClose}
                    severity="success"
                    sx={{
                        color: 'success.main',
                        border: '1px solid green'
                    }}
                >
                    {props.successMessage}
                </Alert>
                :
                <Alert
                    onClose={props.handleSnackbarClose}
                    severity="warning"
                    sx={{
                        color: 'error.main',
                        border: '1px solid red'
                    }}
                >
                    {props.failMessage}
                </Alert>}
        </Snackbar>
    );
}

export default CustomSnackbar;
