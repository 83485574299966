import {Helmet} from "react-helmet";
import 'react-lazy-load-image-component/src/effects/blur.css';
import {useEffect, useState} from "react";
import {Image} from "../../shared/types";
import useFetch from "../Shared/useFetch";
import CustomImageGallery from "../Shared/CustomImageGallery";

function WannaDos() {
    const [images, setImages] = useState<Image[]>([]);

    const FETCH = useFetch("/");

    useEffect(() => {
        const fetchImages = async () => {
            const response = await FETCH.getWannaDoImages();
            setImages(response.images);
        }

        fetchImages().catch(error => {
            console.error(error);
        });
    }, []);

    return (
        <div className="container mx-auto my-2 px-4">
            <Helmet>
                <title>Romy Tattoo & Piercing | Tattoo</title>
                <meta name="description" content="Auswahl aus meinen bereits abgeschlossenen Tattoo Projekten."/>
            </Helmet>
            <h1 className="font-Forum pb-2 text-5xl xl:text-6xl md:text-left text-gray-700 2xl:text-7xl mt-4">Meine Wanna Dos</h1>
            <p className="pb-4 text-lg text-gray-500 2xl:text-xl">Hier sind Motive, die ich bereits gezeichnet, aber noch nicht tätowiert
                habe. Ich tätowiere jedes Motiv individuell und nur 1x.</p>
            <CustomImageGallery images={images}/>
        </div>
    );
}

export default WannaDos;
