import { Helmet } from "react-helmet";
import Bio from "./Bio";
import Welcome from "./Welcome";
import PartnerStudio from "./PartnerStudio";

function Home() {
    return (<>
        <Helmet>
            <title>Romy Tattoo & Piercing | Home</title>
            <meta name="description" content="Jeder hat seine Geschichte. Hier findest du Daten und Fakten über mich und meinen Werdegang." />
        </Helmet>
        <Welcome />
        <Bio />
        <PartnerStudio />
    </>);
}

export default Home;
