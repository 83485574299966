import {Helmet} from "react-helmet";
import 'react-lazy-load-image-component/src/effects/blur.css';
import {useEffect, useState} from "react";
import {Image} from "../../shared/types";
import useFetch from "../Shared/useFetch";
import CustomImageGallery from "../Shared/CustomImageGallery";

function Tattoo() {
    const [images, setImages] = useState<Image[]>([]);

    const FETCH = useFetch("/");

    useEffect(() => {
        const fetchImages = async () => {
            const response = await FETCH.getTattooImages();
            setImages(response.images);
        }

        fetchImages().catch(error => {
            console.error(error);
        });
    }, []);

    return (
        <div className="container mx-auto my-2 px-4">
            <Helmet>
                <title>Romy Tattoo & Piercing | Tattoo</title>
                <meta name="description" content="Auswahl aus meinen bereits abgeschlossenen Tattoo Projekten."/>
            </Helmet>
            <h1 className="font-Forum pb-2 text-5xl xl:text-6xl md:text-left text-gray-700 2xl:text-7xl mt-4">Meine Arbeiten</h1>
            <CustomImageGallery images={images}/>
        </div>
    );
}

export default Tattoo;
