import {ReactComponent as PartnerLogo} from '../../images/PartnerLogo.svg';

function PartnerStudio() {
    return (
        <>
            <div className="container px-4 md:px-8 mx-auto py-8 lg:py-12 bg-gray-100">
                <a href="https://studio-pink.at/de/home.html" target="_blank">
                    <div className="grid grid-cols-1 md:grid-cols-2 border-4 border-gray-900 rounded-lg shadow-2xl">
                        <div className="flex flex-col justify-center items-center bg-white rounded-l-lg py-2">
                            <h2 className="font-Forum text-4xl xl:text-5xl md:text-left text-gray-700 2xl:text-5xl">Mein Partnerstudio</h2>
                            <p className="text-gray-700 text-xl"> im selben Gebäude</p>
                        </div>
                        <div className="flex justify-center items-center bg-gradient-to-t from-gray-900 to-gray-800 p-8">
                            <PartnerLogo className="w-full"/>
                        </div>
                    </div>
                </a>
            </div>
        </>
    );
}

export default PartnerStudio;
