import {
    Button,
    Checkbox,
    FormControl,
    InputLabel,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Select,
    SelectChangeEvent,
    TextField
} from "@mui/material";
import SendIcon from '@mui/icons-material/Send';
import React, {useState} from "react";
import useFetch from "../Shared/useFetch";
import ReCAPTCHA from "react-google-recaptcha";
import {FetchResponse} from "../../shared/types";
import {Helmet} from "react-helmet";
import CustomSnackbar, {CustomSnackbarProps} from "../Shared/CustomSnackbar";

type FormData = {
    firstName: string,
    lastName: string,
    email: string,
    phoneNumber: string,
    piercings: string[],
    preferredDayOfWeek: string,
    preferredTime: string,
}

const defaultFormData = {
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    piercings: [],
    preferredDayOfWeek: "Nicht so wichtig",
    preferredTime: "Nicht so wichtig",
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const piercingTypes = [
    "Standard Lobe",
    "Upper Lobe",
    "Tragus",
    "Inner Conch",
    "Outer Conch",
    "Helix",
    "Forward Helix",
    "Daith",
    "Nasenflügel",
    "Septum",
    "Bauchnabel"];

function TerminPiercing() {
    const FETCH = useFetch("");
    const [captchaIsVerified, setCaptchaIsVerified] = useState(false);
    const [token, setToken] = useState("");
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [actionSuccess, setActionSuccess] = useState(false);
    const [piercings, setPiercings] = useState<string[]>([]);

    const handlePiercingChange = (event: SelectChangeEvent<typeof piercings>) => {
        const {
            target: {value},
        } = event;
        setPiercings(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const [formData, setFormData] = useState<FormData>(defaultFormData);

    const handleSnackbarClose = (_event: React.SyntheticEvent | Event, _reason?: string) => {
        setOpenSnackbar(false);
    };

    const handleRecaptchaChange = (value: any) => {
        setToken(value);
        setCaptchaIsVerified(true);
    }

    const handleChange = (event: any) => {
        event.preventDefault();

        setFormData({
            ...formData,
            [event.target.name]: event.target.value
        })
    }

    const handleSubmit = (event: any) => {
        event.preventDefault();

        if (!captchaIsVerified) {
            return;
        }

        setActionSuccess(false);

        const formContent = new FormData(event.target as HTMLFormElement);
        formContent.append("token", token);
        formContent.append("piercings", piercings.join(', '));

        FETCH.postForm("api/termin-piercing", formContent)
            .then((data: FetchResponse) => {
                setFormData(defaultFormData)
                setPiercings([]);
                if (data.status === "OK") {
                    setActionSuccess(true);
                }
            })
            .catch(() => {
                setActionSuccess(false);
            })
            .finally(() => {
                setOpenSnackbar(true);
            })
    }

    const snackbarProps: CustomSnackbarProps = {
        actionSuccess,
        openSnackbar,
        handleSnackbarClose,
        successMessage: "Deine Terminanfrage wurde erfolgreich abgesendet!",
        failMessage: "Die Terminanfrage konnte leider nicht versendet werden. Schreibe bitte einfach eine Mail an romy-winkler@gmx.at"
    }

    return (
        <div className="container px-4 mx-auto flex-row">
            <Helmet>
                <title>Romy Tattoo & Piercing | Termin</title>
                <meta name="description" content="Sende mir deinen Bevozugten Termin mit allen Details zu deinem Tattoo-Wunschmotiv!"/>
            </Helmet>
            <h1 className="justify-center text-5xl text-gray-700 my-4">Piercing Termin anfragen</h1>
            <p className="pb-4 text-lg text-gray-500 2xl:text-xl">Du kannst ganz einfach mithilfe des Online-Formulares einen Termin
                anfragen. Alles Weitere klären wir dann telefonisch oder per Mail.</p>
            <form id="terminForm" onSubmit={handleSubmit} method="post" className="flex flex-col">
                <div>
                    <FormControl margin="normal" fullWidth>
                        <TextField
                            name="firstName"
                            label="Vorname"
                            variant="outlined"
                            value={formData.firstName}
                            onChange={handleChange}
                            style={{backgroundColor: "white"}}/>
                    </FormControl>

                    <FormControl margin="normal" fullWidth>
                        <TextField
                            name="lastName"
                            label="Nachname"
                            variant="outlined"
                            value={formData.lastName}
                            onChange={handleChange}
                            style={{backgroundColor: "white"}}/>
                    </FormControl>

                    <FormControl margin="normal" fullWidth>
                        <TextField
                            name="email"
                            label="E-Mail"
                            variant="outlined"
                            value={formData.email}
                            onChange={handleChange}
                            style={{backgroundColor: "white"}}/>
                    </FormControl>

                    <FormControl margin="normal" fullWidth>
                        <TextField
                            name="phoneNumber"
                            label="Telefonnummer"
                            variant="outlined"
                            value={formData.phoneNumber}
                            onChange={handleChange}
                            style={{backgroundColor: "white"}}/>
                    </FormControl>

                    <FormControl margin="normal" fullWidth>
                        <InputLabel id="demo-multiple-checkbox-label">Piercing</InputLabel>
                        <Select
                            labelId="demo-multiple-checkbox-label"
                            id="demo-multiple-checkbox"
                            multiple
                            value={piercings}
                            onChange={handlePiercingChange}
                            input={<OutlinedInput label="Tag"/>}
                            renderValue={(selected) => selected.join(', ')}
                            MenuProps={MenuProps}
                            style={{backgroundColor: "white"}}
                        >
                            {piercingTypes.map((name: any) => (
                                <MenuItem key={name} value={name}>
                                    <Checkbox checked={piercings.indexOf(name) > -1}/>
                                    <ListItemText primary={name}/>
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <FormControl margin="normal" fullWidth>
                        <InputLabel id="dayOfWeekLabel">Bevorzugter Wochentag</InputLabel>
                        <Select
                            name="preferredDayOfWeek"
                            labelId="dayOfWeekLabel"
                            label="Bevorzugter Wochentag"
                            variant="outlined"
                            value={formData.preferredDayOfWeek}
                            onChange={handleChange}
                            style={{backgroundColor: "white"}}
                        >
                            <MenuItem value="Nicht so wichtig">Nicht so wichtig</MenuItem>
                            <MenuItem value="Montag">Montag</MenuItem>
                            <MenuItem value="Dienstag">Dienstag</MenuItem>
                            <MenuItem value="Mittwoch">Mittwoch</MenuItem>
                            <MenuItem value="Donnerstag">Donnerstag</MenuItem>
                            <MenuItem value="Freitag">Freitag</MenuItem>
                            <MenuItem value="Samstag">Samstag</MenuItem>
                        </Select>
                    </FormControl>

                    <FormControl margin="normal" fullWidth>
                        <InputLabel id="dayOfWeekLabel">Bevorzugte Uhrzeit</InputLabel>
                        <Select
                            name="preferredTime"
                            labelId="dayOfWeekLabel"
                            label="Bevorzugte Uhrzeit"
                            variant="outlined"
                            value={formData.preferredTime}
                            onChange={handleChange}
                            style={{backgroundColor: "white"}}
                        >
                            <MenuItem value="Nicht so wichtig">Nicht so wichtig</MenuItem>
                            <MenuItem value="09:00">09:00</MenuItem>
                            <MenuItem value="10:00">10:00</MenuItem>
                            <MenuItem value="11:00">11:00</MenuItem>
                            <MenuItem value="12:00">12:00</MenuItem>
                            <MenuItem value="13:00">13:00</MenuItem>
                            <MenuItem value="14:00">14:00</MenuItem>
                            <MenuItem value="15:00">15:00</MenuItem>
                            <MenuItem value="16:00">16:00</MenuItem>
                            <MenuItem value="17:00">17:00</MenuItem>
                            <MenuItem value="18:00">18:00</MenuItem>
                            <MenuItem value="19:00">19:00</MenuItem>
                        </Select>
                    </FormControl>

                    <ReCAPTCHA
                        sitekey="6Lcyh30mAAAAAC_EUZiMidPYHQUAqiML4P2isacM"
                        onChange={handleRecaptchaChange}
                        hl="de-AT"
                        size="normal"
                        className="w-full"
                    />

                    <FormControl margin="dense">
                        <Button sx={{backgroundColor: '#45769c'}} type="submit" variant="contained" endIcon={<SendIcon/>}>Absenden</Button>
                    </FormControl>
                </div>
            </form>
            <CustomSnackbar {...snackbarProps}/>
        </div>
    );
}

export default TerminPiercing;
