import {getCookieConsentValue, resetCookieConsentValue} from "react-cookie-consent";
import {GoogleMap, InfoWindowF, LoadScript, MarkerF} from '@react-google-maps/api';
import {useEffect, useState} from 'react';
import {Helmet} from "react-helmet";
import {useOutletContext} from "react-router-dom";

const containerStyle = {
    width: '100%',
    height: '100%',
    overflow: 'hidden',
};
const center = {
    lat: 47.8031396,
    lng: 13.0726063
};

function Anfahrt() {
    const [showInfoWindow, setShowInfoWindow] = useState(true);
    const [acceptCookies, setAcceptCookies]: any = useOutletContext();

    const toggleInfoWindow = () => {
        setShowInfoWindow(!showInfoWindow);
    }

    useEffect(() => {
        if (getCookieConsentValue() === "false") {
            resetCookieConsentValue();
            document.location.reload()
        } else if (getCookieConsentValue() !== "true") {
            resetCookieConsentValue();
        }
    }, [])

    return (
        <div className="relative w-full h-[calc(100vh-8rem)]">
            <Helmet>
                <title>Romy Tattoo & Piercing | Anfahrt</title>
                <meta name="description" content=""/>
            </Helmet>
            {acceptCookies &&
                <LoadScript googleMapsApiKey="AIzaSyAIRca_qfm1hRtCc9cwq0N0mK0hbKUa1UU">
                    <GoogleMap
                        mapContainerStyle={containerStyle}
                        center={center}
                        zoom={13}
                    >
                        <>
                            <MarkerF position={center} onClick={toggleInfoWindow}>
                                {showInfoWindow && <InfoWindowF position={center} onCloseClick={toggleInfoWindow}>
                                    <div>
                                        <p className='text-xl font-bold'>Romy Tattoos</p>
                                        <p className='text-lg'> Anton-Breitner-Straße 9</p>
                                        <p className='text-lg'>5020 Salzburg/Parsch</p>
                                        <a className="text-petrol-400" target="_blank" href="https://www.google.com/maps/dir//Romy+Tattoo+%26+Piercing,+Anton-Breitner-Stra%C3%9Fe+9,+5020+Salzburg/@47.8032206,12.9905453,12z/data=!3m1!5s0x47769099a4f91013:0xe632dfeacd825b6c!4m9!4m8!1m0!1m5!1m1!1s0x477691247be4c89d:0xc5daedcdb5c6bcc6!2m2!1d13.0729447!2d47.8031348!3e0?entry=ttu">
                                            <p className='text-lg'>View on Google Maps</p>
                                        </a>
                                    </div>
                                </InfoWindowF>}
                            </MarkerF>
                        </>
                    </GoogleMap>
                </LoadScript>
            }
        </div>
    )
}

export default Anfahrt;
