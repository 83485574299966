import {useEffect, useState} from "react";
import useFetch from "../../Shared/useFetch";
import {Image} from "../../../shared/types";
import EditableImageGallery from "../Shared/EditableImageGallery";
import CustomSnackbar, {CustomSnackbarProps} from "../../Shared/CustomSnackbar";

function AdminTattooEdit() {
    const [images, setImages] = useState<Image[]>([]);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [actionSuccess, setActionSuccess] = useState(false);

    const FETCH = useFetch("/");

    useEffect(() => {
        const fetchImages = async () => {
            const response = await FETCH.getTattooImages();
            setImages(response.images);
        }

        fetchImages().catch(error => {
            console.error(error);
        });
    }, []);

    const handleRemoveImage = (index: number) => {
        setActionSuccess(false);

        FETCH.deleteTattooImage(index)
            .catch(err => {
                console.log(err);
            })
            .then(() => {
                setImages(
                    images.filter(image => image.id !== index)
                );
                setActionSuccess(true);
            })
            .finally(() => {
                setOpenSnackbar(true);
            });
    }

    const handleSnackbarClose = (_event: React.SyntheticEvent | Event, _reason?: string): void => {
        setOpenSnackbar(false);
    };

    const snackbarProps: CustomSnackbarProps = {
        actionSuccess: actionSuccess,
        openSnackbar,
        handleSnackbarClose,
        successMessage: "Das Bild wurde erfolgreich gelöscht.",
        failMessage: "Das Bild konnte nicht gelöscht werden."
    }

    return (
        <div className="container mx-auto my-2 px-4">
            <h1 className="font-Forum pb-2 text-5xl xl:text-6xl md:text-left text-gray-700 2xl:text-7xl mt-4">Meine Arbeiten</h1>
            <EditableImageGallery images={images} handleRemoveImage={handleRemoveImage}/>
            <CustomSnackbar {...snackbarProps}/>
        </div>
    );
}

export default AdminTattooEdit;
