import piercingStandardLobe from "../../images/piercing-standard-lobe.png";
import piercingUpperLobe from "../../images/piercing-upper-lobe.png";
import piercingTragus from "../../images/piercing-tragus.png";
import piercingInnerConch from "../../images/piercing-inner-conch.png";
import piercingOuterConch from "../../images/piercing-outer-conch.png";
import piercingHelix from "../../images/piercing-helix.png";
import piercingForwardHelix from "../../images/piercing-forward-helix.png";
import piercingDaith from "../../images/piercing-daith.png";
import piercingNasenfluegel from "../../images/piercing-nasenfluegel.png";
import piercingSeptum from "../../images/piercing-septum.png";
import piercingBauchnabel from "../../images/piercing-bauchnabel.png";
import PiercingGallery, {PiercingDetails} from "./PiercingGallery";

function Piercing() {
    return (
        <div className="container mx-auto my-2 px-4">
            <h1 className="font-Forum pb-2 text-5xl xl:text-6xl md:text-left text-gray-700 2xl:text-7xl mt-4">Piercings & Preise</h1>
            <p className="pb-4 text-lg text-gray-500 2xl:text-xl">Alle Preise beinhalten den als Ersteinsatz geeigneten Schmuck. Ich lege
                größten Wert auf Hygiene und Qualität. Gerne erfülle ich dir deinen Piercing-Wunsch nach ausführlicher Beratung, unter
                Verwendung von moderner Technik und hochwertigen Materialien.</p>

            <h2 className="font-Forum pb-2 text-4xl xl:text-5xl md:text-left text-gray-700 2xl:text-5xl mt-4">
                Ohren
            </h2>
            <PiercingGallery piercings={piercingsOhren}/>
            <h2 className="font-Forum pb-2 text-4xl xl:text-5xl md:text-left text-gray-700 2xl:text-5xl mt-4">
                Nase
            </h2>
            <PiercingGallery piercings={piercingsNase}/>
            <h2 className="font-Forum pb-2 text-4xl xl:text-5xl md:text-left text-gray-700 2xl:text-5xl mt-4">
                Bauchnabel
            </h2>
            <PiercingGallery piercings={piercingsBauchnabel}/>
        </div>
    );
}

const piercingsOhren: PiercingDetails[] = [
    {
        "title": "Lobe (Ohrläppchen)",
        "price": 30,
        "duration": "ca. 20",
        "description": "",
        "src": piercingStandardLobe
    },
    {
        "title": "Upper Lobe",
        "price": 45,
        "duration": "ca. 20",
        "description": "",
        "src": piercingUpperLobe
    },
    {
        "title": "Tragus",
        "price": 60,
        "duration": "ca. 20",
        "description": "",
        "src": piercingTragus
    },
    {
        "title": "Inner Conch",
        "price": 60,
        "duration": "ca. 20",
        "description": "",
        "src": piercingInnerConch
    },
    {
        "title": "Outer Conch",
        "price": 60,
        "duration": "ca. 20",
        "description": "",
        "src": piercingOuterConch
    },
    {
        "title": "Helix",
        "price": 60,
        "duration": "ca. 20",
        "description": "",
        "src": piercingHelix
    },
    {
        "title": "Forward Helix",
        "price": 60,
        "duration": "ca. 20",
        "description": "",
        "src": piercingForwardHelix
    },
    {
        "title": "Daith",
        "price": 60,
        "duration": "ca. 20",
        "description": "",
        "src": piercingDaith
    },
];

const piercingsNase: PiercingDetails[] = [
    {
        "title": "Nasenflügel",
        "price": 60,
        "duration": "ca. 20",
        "description": "",
        "src": piercingNasenfluegel
    },
    {
        "title": "Septum",
        "price": 65,
        "duration": "ca. 20",
        "description": "",
        "src": piercingSeptum
    },
]
const piercingsBauchnabel: PiercingDetails[] = [
    {
        "title": "Bauchnabel",
        "price": 70,
        "duration": "ca. 20",
        "description": "",
        "src": piercingBauchnabel
    },
]
export default Piercing;


