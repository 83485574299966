import React, {useState} from "react";
import FileUpload from "../../Shared/FileUpload";
import useFetch from "../../Shared/useFetch";
import {FetchResponse} from "../../../shared/types";
import {Box, Button, FormControl, LinearProgress} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import CustomSnackbar, {CustomSnackbarProps} from "../../Shared/CustomSnackbar";

function AdminTattoo() {
    const FETCH = useFetch("/");
    const [files, setFiles] = useState<File[]>([]);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [actionSuccess, setActionSuccess] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleSubmit = (event: any) => {
        event.preventDefault();

        if (files.length < 1) {
            return;
        }

        setLoading(true);
        setActionSuccess(false);

        const formContent = new FormData(event.target as HTMLFormElement);
        files.forEach(file => {
            formContent.append("images", file as Blob);
        })

        FETCH.postForm("api/admin/upload-tattoo", formContent)
            .then((data: FetchResponse) => {
                setFiles([]);
                if (data.status === "OK") {
                    setActionSuccess(true);
                }
            })
            .catch(err => {
                console.log(err);
            })
            .finally(() => {
                setLoading(false);
                setOpenSnackbar(true);
            })
    }

    const handleSnackbarClose = (_event: React.SyntheticEvent | Event, _reason?: string): void => {
        setOpenSnackbar(false);
    };

    const snackbarProps: CustomSnackbarProps = {
        actionSuccess: actionSuccess,
        openSnackbar,
        handleSnackbarClose,
        successMessage: "Die Bilder wurden erfolgreich hochgeladen.",
        failMessage: "Die Bilder konnten nicht hochgeladen werden."
    }

    return (
        <div className="container px-4 mx-auto flex-row">
            <h1 className="text-center text-5xl text-gray-700 my-4">Tattoos</h1>
            <div className="mx-auto flex justify-center">
                {loading && <Box sx={{width: '100%'}}>
                    <LinearProgress/>
                </Box>}
                {!loading && <form id="terminForm" onSubmit={handleSubmit} method="post" className="flex flex-col">
                    <FileUpload files={files} setFiles={setFiles}/>

                    <FormControl margin="dense">
                        <Button type="submit" variant="contained" endIcon={<SendIcon/>}>Absenden</Button>
                    </FormControl>
                </form>}
            </div>
            <CustomSnackbar {...snackbarProps}/>
        </div>
    )
}

export default AdminTattoo;
