import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Home from './components/Home/Home';
import Anfahrt from './components/Anfahrt/Anfahrt';
import Impressum from './components/Impressum/Impressum';
import Tattoo from './components/Tattoo/Tattoo';
import WannaDos from './components/WannaDos/WannaDos';
import Piercing from './components/Piercing/Piercing';
import TerminTattoo from './components/Termin/TerminTattoo';
import TerminPiercing from './components/Termin/TerminPiercing';
import AdminHome from "./components/Admin/AdminHome/AdminHome";
import AdminTattoo from "./components/Admin/AdminTattoo/AdminTattoo";
import AdminWannaDo from "./components/Admin/AdminWannaDo/AdminWannaDo";
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import AdminTattooEdit from "./components/Admin/AdminTattoo/AdminTattooEdit";
import AdminWannaDoEdit from "./components/Admin/AdminWannaDo/AdminWannaDoEdit";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

const router = createBrowserRouter([
        {
            path: "/",
            element: <App/>,
            children:
                [
                    {
                        path: "/",
                        element: <Home/>
                    },
                    {
                        path: "/tattoo",
                        element: <Tattoo/>
                    },
                    {
                        path: "/wannados",
                        element: <WannaDos/>
                    },
                    {
                        path: "/piercing",
                        element: <Piercing/>
                    },
                    {
                        path: "/terminTattoo",
                        element: <TerminTattoo/>
                    },
                    {
                        path: "/terminPiercing",
                        element: <TerminPiercing/>
                    },
                    {
                        path: "/anfahrt",
                        element: <Anfahrt/>
                    },
                    {
                        path: "/impressum",
                        element: <Impressum/>,
                    },
                    {
                        path: "/admin",
                        element: <AdminHome/>,
                    },
                    {
                        path: "/admin/tattoo",
                        element: <AdminTattoo/>,
                    },
                    {
                        path: "/admin/tattoo/edit",
                        element: <AdminTattooEdit/>,
                    },
                    {
                        path: "/admin/wanna-dos/edit",
                        element: <AdminWannaDoEdit/>,
                    },
                    {
                        path: "/admin/wanna-dos",
                        element: <AdminWannaDo/>,
                    },
                ],
        },
    ])
;

root.render(
    <>
        <React.StrictMode>
            <RouterProvider router={router}/>
        </React.StrictMode>
    </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
