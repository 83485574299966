import {Box, IconButton, ImageList, ImageListItem, useMediaQuery} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import {Image} from "../../../shared/types";
import {useEffect, useState} from "react";

type EditableImageGalleryProps = {
    images: Image[],
    handleRemoveImage: (id: number) => void,
}

function EditableImageGallery(props: EditableImageGalleryProps) {
    const [cols, setCols] = useState(1);

    const mediaXs = useMediaQuery('(min-width:640px)');
    const mediaMd = useMediaQuery('(min-width:768px)');
    const mediaLg = useMediaQuery('(min-width:1024px)');
    const mediaXl = useMediaQuery('(min-width:1536px)');

    useEffect(() => {
        if (mediaXs) {
            setCols(1);
        }
        if (mediaMd) {
            setCols(2);
        }
        if (mediaLg) {
            setCols(3);
        }
        if (mediaXl) {
            setCols(4);
        }
    }, [mediaXs, mediaMd, mediaLg, mediaXl])

    return (
        <div className="block">
            <Box>
                <ImageList variant="masonry" cols={cols} gap={4}>
                    {props.images.map((item) => (
                        <ImageListItem key={item.id}>
                            <div className="-mb-[7px]">
                                <div key={`delContainer${item.thumbnailPath}`} className="relative">
                                    <div key={`delDiv${item.thumbnailPath}`} onClick={() => props.handleRemoveImage(item.id)}
                                         className="absolute flex align-middle justify-center top-2 right-2 w-8 h-8 rounded hover:bg-white/100 bg-white/75">
                                        <IconButton color={"error"} key={`delButton${item.thumbnailPath}`} aria-label="delete"
                                                    size="large">
                                            <DeleteIcon key={`del${item.thumbnailPath}`} fontSize="inherit"/>
                                        </IconButton>
                                    </div>
                                    <img
                                        className="hover:cursor-pointer"
                                        src={`${item.thumbnailPath}`}
                                    />
                                </div>
                            </div>
                        </ImageListItem>
                    ))}
                </ImageList>
            </Box>
        </div>
    )
}

export default EditableImageGallery;
