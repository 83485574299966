import React from "react";
import {Link} from "react-router-dom";

function AdminHome() {
    return (
        <div className="container px-4 mx-auto flex-row">
            <h1 className="text-center text-5xl text-gray-700 my-4">Hallo, Admin!</h1>
            <div className="mx-auto flex justify-items-center items-center">
                <div className="mx-auto lg:flex flex-wrap gap-4">
                    <Link to="/admin/tattoo">
                        <div className="mb-4 lg:mb-0 mx-auto flex-row p-8 bg-gradient-to-t from-gray-900 to-gray-800 rounded-lg hover:bg-red-400 w-full">
                            <h1 className="justify-center text-white align-center">Tattoo hochladen</h1>
                        </div>
                    </Link>
                    <Link to="/admin/tattoo/edit">
                        <div className="mb-4 lg:mb-0 mx-auto flex-row p-8 bg-gradient-to-t from-gray-900 to-gray-800 rounded-lg hover:bg-red-400">
                            <h1 className="justify-center text-white align-center">Tattoos editieren</h1>
                        </div>
                    </Link>
                    <Link to="/admin/wanna-dos">
                        <div className="mb-4 lg:mb-0 mx-auto flex-row p-8 bg-gradient-to-t from-gray-900 to-gray-800 rounded-lg">
                            <h1 className="justify-center text-white align-center">Wanna-Do hochladen</h1>
                        </div>
                    </Link>
                    <Link to="/admin/wanna-dos/edit">
                        <div className="mb-4 lg:mb-0 mx-auto flex-row p-8 bg-gradient-to-t from-gray-900 to-gray-800 rounded-lg">
                            <h1 className="justify-center text-white align-center">Wanna-Dos editieren</h1>
                        </div>
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default AdminHome;
