import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import React, {useState} from 'react';
import {Outlet,} from "react-router-dom";
import CookieConsent, {getCookieConsentValue} from "react-cookie-consent";
import './App.css';
import Navbar from './components/Shared/Navbar';
import Footer from './components/Shared/Footer';
import {Helmet} from 'react-helmet';

function App() {
    const [acceptCookies, setAcceptCookies] = useState(getCookieConsentValue() === "true");

    return (
        <>
            <Helmet>
                <html lang="de"/>
                <title>Romy Tattoo & Piercing</title>
                <meta name="description" content=""/>
                <meta name="viewport" content="width=device-width, initial-scale=1"/>
                <meta charSet="utf-8"/>
            </Helmet>
            <main className="bg-gray-100 flex flex-col justify-between min-h-screen">
                <Navbar/>
                <Outlet context={[acceptCookies, setAcceptCookies]}/>
                <Footer/>
            </main>
            <CookieConsent
                onAccept={() => setAcceptCookies(true)}
                onDecline={() => setAcceptCookies(false)}
                buttonText="Einverstanden"
                disableStyles={true}
                containerClasses="lg:flex lg:items-center lg:justify-around w-full bg-white p-4 box-shadow border-t border-gray-900 fixed bottom-0 text-black"
                buttonClasses="self-center border border-gray-800 text-white bg-petrol-400 rounded hover:bg-petrol-600 mt-2 p-2"
                declineButtonClasses="self-center border border-gray-800 text-white bg-petrol-400 rounded hover:bg-petrol-600 ml-4 mt-2 p-2"
                declineButtonText="Ablehnen"
                enableDeclineButton
                flipButtons
            >
                <p>Wir verwenden Cookies und brauchen deine Zustimmung, um Google Maps auf dieser Webseite anzeigen zu dürfen.</p>
            </CookieConsent>
        </>
    );
}

export default App;
