import {useMediaQuery} from "@mui/material";
import {LazyLoadImage} from "react-lazy-load-image-component";
import 'react-lazy-load-image-component/src/effects/blur.css';
import {useEffect, useState} from "react";
import {Image} from "../../shared/types";
import clsx from "clsx";

type ImageGalleryProps = {
    images: Image[]
}

function CustomImageGallery(props: ImageGalleryProps) {
    const [viewerIsHidden, setViewerIsHidden] = useState(true);
    const [viewedImage, setViewedImage] = useState("");

    const [cols, setCols] = useState<Image[][]>([]);

    const mediaXs = useMediaQuery('(min-width:0px)');
    const mediaMd = useMediaQuery('(min-width:768px)');
    const mediaLg = useMediaQuery('(min-width:1024px)');
    const mediaXl = useMediaQuery('(min-width:1536px)');

    const viewerClasses = clsx({
        "hidden": viewerIsHidden,
    });

    useEffect(() => {
        buildImageArray();
    }, [mediaXs, mediaMd, mediaLg, mediaXl, props.images])

    const buildImageArray = () => {
        const arr: Image[][] = [];

        if (mediaXl) {
            arr[0] = [];
            arr[1] = [];
            arr[2] = [];
            arr[3] = [];
            props.images.forEach((img, index) => {
                if (index % 4 === 0) {
                    arr[0].push(img);
                }
                if (index % 4 === 1) {
                    arr[1].push(img);
                }
                if (index % 4 === 2) {
                    arr[2].push(img);
                }
                if (index % 4 === 3) {
                    arr[3].push(img);
                }
            })
        } else if (mediaLg) {
            arr[0] = [];
            arr[1] = [];
            arr[2] = [];
            props.images.forEach((img, index) => {
                if (index % 3 === 0) {
                    arr[0].push(img);
                }
                if (index % 3 === 1) {
                    arr[1].push(img);
                }
                if (index % 3 === 2) {
                    arr[2].push(img);
                }
            })
        } else if (mediaMd) {
            arr[0] = [];
            arr[1] = [];
            props.images.forEach((img, index) => {
                index % 2 === 0
                    ? arr[0].push(img)
                    : arr[1].push(img)
            })
        } else if (mediaXs) {
            arr[0] = props.images;
        }
        setCols(arr);
    }

    const customImageHandler = (id: number) => {
        document.body.setAttribute("style", "overflow: hidden;");

        let viewportmeta = document.querySelector('meta[name="viewport"]');
        viewportmeta?.setAttribute('content', "initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0");

        const image = props.images.find(img => img.id === id)
        if (image) {
            setViewedImage(image.imagePath);
        }

        setViewerIsHidden(false);
    }

    const handleViewerClose = () => {
        document.body.setAttribute("style", "overflow: visible;");

        let viewportmeta = document.querySelector('meta[name="viewport"]');
        viewportmeta?.setAttribute('content', "initial-scale=1.0");

        setViewerIsHidden(true);
        setViewedImage("");
    }

    return (
        <>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-2">
                    {cols.map((col, index) => {
                        return <div key={`div-${index}`} className="grid justify-self-center">
                            <>
                            <span>
                                {
                                    col.map((item, index) => {
                                        return <div key={index} className="min-w-4 min-h-72 mb-[2px]">
                                            <LazyLoadImage
                                                className="h-auto hover:cursor-pointer filter hover:brightness-110"
                                                height="auto"
                                                width="100%"
                                                onClick={() => customImageHandler(item.id)}
                                                src={`${item.thumbnailPath}`}
                                                effect="blur"
                                                placeholder={<div className="w-4 h-72"></div>}
                                                threshold={0}
                                            />
                                        </div>
                                    })}
                            </span>
                            </>
                        </div>
                    })}
            </div>

            <div id="image-viewer"
                 className={viewerClasses + " w-screen h-screen fixed top-0 left-0 z-10 bg-contain bg-black bg-opacity-90"}
                 onClick={handleViewerClose}>
                <img alt="Tattoo made by Romy" src={viewedImage} className="object-contain mx-auto w-full h-full lg:p-8"/>
            </div>
        </>
    );
}

export default CustomImageGallery;
