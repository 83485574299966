type PiercingCardProps = {
    src: string,
    title: string,
    description: string,
    price: number
}

function PiercingCard(props: PiercingCardProps) {
    return (
        <div className="bg-white shadow-lg">
            <div className="border-petrol-800 border-t-8 border-l-8 border-r-8">
                <img src={props.src} className="h-72 p-4 mx-auto" alt={props.description}/>
            </div>
            <div className="flex flex-row justify-between w-full p-4 bg-gradient-to-b from-gray-800 to-gray-900">
                <h2 className="text-center font-bold text-white text-2xl">{props.title}</h2>
                <p className="text-center text-white text-xl">{props.price} &euro;</p>
            </div>
        </div>
    );
}

export default PiercingCard;
