import React, { useEffect, useState } from "react";
import ContactJson from "../../data/contact.json";
import { ContactContent } from '../../shared/types';
import {Link} from "react-router-dom";

function Footer() {

    const [contact, setContact] = useState<ContactContent>();

    useEffect(() => {
        setContact(ContactJson);
    }, []);

    return (<>
        <div className="self-end w-screen border-t-2 border-gray-100 py-4 bg-gray-900">
            <div className="lg:hidden">
                <div className="container px-4 mx-auto w-full">
                    <div className="pb-2 text-white md:flex md:justify-between">
                        <div className="flex items-center mb-1">
                            <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6 text-petrol-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
                            </svg>
                            <span className="ml-2 text-lg">{contact?.phone}</span>
                        </div>
                        <div className="flex items-center mb-1">
                            <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6 text-petrol-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                            </svg>
                            <span className="ml-2 text-lg">{contact?.email}</span>
                        </div>
                        <div className="flex items-center">
                            <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6 text-petrol-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15.666 3.888A2.25 2.25 0 0 0 13.5 2.25h-3c-1.03 0-1.9.693-2.166 1.638m7.332 0c.055.194.084.4.084.612v0a.75.75 0 0 1-.75.75H9a.75.75 0 0 1-.75-.75v0c0-.212.03-.418.084-.612m7.332 0c.646.049 1.288.11 1.927.184 1.1.128 1.907 1.077 1.907 2.185V19.5a2.25 2.25 0 0 1-2.25 2.25H6.75A2.25 2.25 0 0 1 4.5 19.5V6.257c0-1.108.806-2.057 1.907-2.185a48.208 48.208 0 0 1 1.927-.184" />
                            </svg>
                            <span className="ml-2 text-lg">
                                <Link className="hover:font-bold" to="/impressum">Impressum</Link>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex relative flex-col justify-end items-center w-full bg-gray-900">
                <div className="w-full">
                    <div className="container xl:w-5/6 2xl:w-4/6 px-4 py-8 mx-auto h-12">
                        <div className="flex flex-row-reverse justify-between h-full">
                            <div className="flex lg:flex-row-reverse md:gap-8">
                                <div className="lg:flex items-center text-white hidden">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6 text-petrol-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15.666 3.888A2.25 2.25 0 0 0 13.5 2.25h-3c-1.03 0-1.9.693-2.166 1.638m7.332 0c.055.194.084.4.084.612v0a.75.75 0 0 1-.75.75H9a.75.75 0 0 1-.75-.75v0c0-.212.03-.418.084-.612m7.332 0c.646.049 1.288.11 1.927.184 1.1.128 1.907 1.077 1.907 2.185V19.5a2.25 2.25 0 0 1-2.25 2.25H6.75A2.25 2.25 0 0 1 4.5 19.5V6.257c0-1.108.806-2.057 1.907-2.185a48.208 48.208 0 0 1 1.927-.184" />
                                    </svg>
                                    <span className="ml-2 text-lg">
                                        <Link className="hover:font-bold" to="/impressum">Impressum</Link>
                                    </span>
                                </div>
                                <div className="lg:flex items-center text-white hidden">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6 text-petrol-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
                                    </svg>
                                    <span className="ml-2 text-lg">{contact?.phone}</span>
                                </div>
                                <div className="lg:flex items-center text-white hidden">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6 text-petrol-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                                    </svg>
                                    <span className="ml-2 text-lg">{contact?.email}</span>
                                </div>
                            </div>
                            <div className="flex justify-center items-center h-full">
                                <a href="https://www.instagram.com/romy.winkler/?hl=en" rel="noreferrer" target="_blank">
                                    <div className="w-12 h-12 bg-center bg-contain bg-logo-insta">

                                    </div>
                                </a>
                                <a href="https://www.facebook.com/romywinklerart" rel="noreferrer" target="_blank">
                                    <div className="ml-4 w-12 h-12 bg-center bg-contain lg:ml-6 bg-logo-fb">

                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>);
}

export default Footer;
